html, body {
  font-family: Roboto,Helvetica Neue,Helvetica,Arial,sans-serif !important;
  font-size: 16px;
}

p, a, div, span, table, tr, th, td, h1, h2, h3, h4, h5, h6 {
  font-family: 'roboto', 'helvetica', 'sans-serif' !important;
}

/* SEMANTIC UI REACT OVERRIDES */
.ui.sticky { 
  z-index: 1001!important;
}
